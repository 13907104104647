import { useState, useEffect } from "react";
import { useToast } from "react-toastify";
import { useAppContext } from "../contexts/app.context";

const TEMPRATURE_SERVICE = '0000ffc0-0000-1000-8000-00805f9b34fb';
const NAME_PREFIX = 'K';
const TEMPRATURE_CHARECTERISTIC = '0000ffc7-0000-1000-8000-00805f9b34fb';

const scanOption = {
  acceptAllDevices: true,
  optionalServices: [TEMPRATURE_SERVICE]
};

export function useBluetooth() {
  const [device, setDevice] = useState(null);
  const [server, setServer] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [temperature, setTemperature] = useState(null);
  const { showAlert } = useAppContext()
  const [status, setStatus] = useState('Click the button to connect to a BLE device');


  // Function to connect to BLE device
  const requestDevice = async () => {
    // KBPro_513242
    // KBPro_513207
    try {
      await connectToDevice()
    } catch (error) {
      console.error("Error connecting to device:", error);
    }
  };

  async function connectToDevice() {
    try {
      setStatus('Requesting BLE device...');
      const device = await navigator.bluetooth.requestDevice(scanOption);

      setStatus('Connecting to GATT server...');
      const server = await device.gatt.connect();

      // Use a known service or device information
      const service = await server.getPrimaryService(TEMPRATURE_SERVICE); // Replace with the correct service UUID
      const characteristics = await service.getCharacteristics();

      console.log('Available Characteristics:', characteristics);

      // Loop through characteristics to find the one with temperature

      const characteristic = await service.getCharacteristic(TEMPRATURE_CHARECTERISTIC);
      characteristic.startNotifications().then(() => {
        characteristic.addEventListener('characteristicvaluechanged', event => {
          console.log(event.target);
          const value1 = event.target.value.getUint8(1);
          const value2 = event.target.value.getUint8(2); // Get the first byte as an unsigned 8-bit integer
          setTemperature(`${value1}.${value2}`);
        });
      });
    } catch (error) {
      console.error('Error:', error);
      setStatus('Failed to connect or read data from the device.');
    }
  }


  // Function to disconnect BLE device
  const disconnectDevice = () => {
    if (device && device.gatt.connected) {
      device.gatt.disconnect();
      setIsConnected(false);
      console.log("Device disconnected");
    }
  };

  useEffect(() => {
    if (device) {
      device.addEventListener("gattserverdisconnected", () => {
        setIsConnected(false);
        console.log("Device disconnected");
      });
    }
    return () => {
      if (device) {
        device.removeEventListener("gattserverdisconnected", () => {
          setIsConnected(false);
        });
      }
    };
  }, [device]);

  return { requestDevice, disconnectDevice, isConnected, temperature };
}
